/* eslint-disable react/prop-types */

import React  from 'react';

import { observer } from 'mobx-react';

import { StringSelect } from '../general/StringSelect';

const scanTypeOpts = ['prepared', 'packed', 'sent'];

const ScanSettings = observer(({ orderStore, onSettings }) => {
    const [settings, setSettings] = React.useState({ scanType: 'prepared' });

    const [schoolOpts, orderTypeOpts] = React.useMemo(
        () => {
            const schoolSet = new Set(), orderTypeSet = new Set();

            for (const order of orderStore.orders) {
                schoolSet.add(order.school);
                orderTypeSet.add(order.orderType);
            }

            return [
                ['Any', ...[...schoolSet].sort()],
                ['Any', ...[...orderTypeSet].sort()],
            ];
        },
        [orderStore.orders]
    );

    const set = v => {
        setSettings(prev => {
            const updated = { ...prev, ...v };
            if (updated.school && updated.orderType && updated.scanType) onSettings(updated);
            return updated;
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'left' }}>
            <div style={{ marginLeft: '1em', width: '20em' }}>
                <StringSelect
                    options={schoolOpts}
                    placeholder='Select a school'
                    value={settings.school}
                    onChange={(_id, value) => set({ school: value })}
                />
            </div>
            <div style={{ marginLeft: '1em', width: '10em' }}>
                <StringSelect
                    options={orderTypeOpts}
                    placeholder='Select break'
                    value={settings.orderType}
                    onChange={(_id, value) => set({ orderType: value })}
                />
            </div>
            <div style={{ marginLeft: '1em', display: 'flex', alignItems: 'baseline' }}>
                <strong style={{ color: '#666' }}>Scan to:&nbsp;</strong>
                <StringSelect
                    options={scanTypeOpts}
                    placeholder='Select scan mode'
                    value={settings.scanType}
                    onChange={(_id, value) => set({ scanType: value })}
                    width='10em'
                />
            </div>
        </div>
    );
});

export {
    ScanSettings,
}
