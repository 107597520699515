/* eslint-disable react/prop-types */

import React  from 'react';

import { Spinner, Button } from 'react-bootstrap';

import { observer } from 'mobx-react';

import { transferSchema } from '../../schema/schema';

import { FormElement } from '../general/FormElement';
import { StringSelect } from '../general/StringSelect';
import { EnumSelect } from '../general/EnumSelect';
import { NumberInput } from '../general/NumberInput';

import { Validated } from '../validated/Validated';

const unitList = ['each', 'batch', 'kg', 'packet', 'carton'];

const TransferEdit = observer(({ transferStore, api, onError, onClose }) => {
    const [itemList, setItemList] = React.useState(null);
    const [siteMap, setSiteMap] = React.useState(null);
    const [transferValue, setTransferValue] = React.useState({
        item: '',
        quantity: 1,
        unit: unitList[0],
        toSiteId: null,
        fromSiteId: null,
    });
    const [allValid, setAllValid] = React.useState(false);
    const invalidSet = React.useRef(new Set([]));

    React.useEffect(
        () => {
            api.axios.get('/transfers/items').then(res => {
                setItemList(res.data);
            })
            .catch(err => {
                onError(err);
            });

            Promise.all([
                api.axios.get('/users/sites'),
                api.axios.get('/transfers/sites'),
            ]).then(res => {
                const userSiteMap = new Map(res[0].data.sites.map(e => [e.id, e.name]));
                const siteMap = new Map(res[1].data.sites.map(e => [e.id, e.name]));
                setTransferValue(
                    prev => ({
                        ...prev,
                        toSiteId: res[0]?.data.sites[0]?.id || null,
                        fromSiteId: res[1]?.data.sites[0]?.id || null
                    })
                );
                setSiteMap({ all: siteMap, user: userSiteMap });
            })
            .catch(err => {
                onError(err);
            });

            return () => {};
        }, []
    );

    const handleInitialValidation = (id, validation) => {
        if (validation.valid) invalidSet.current.delete(id);
        else invalidSet.current.add(id);
        setAllValid(invalidSet.size === 0);
    };

    const handleChange = (id, value, invalid) => {
        if (invalid) invalidSet.current.add(id);
        else invalidSet.current.delete(id);
        setAllValid(invalidSet.current.size === 0);

        setTransferValue(
            prev => ({
                ...prev,
                [id]: value
            })
        );
    };

    const handleSubmit = () => {
        const data = {
            ...transferValue,
        };

        transferStore.createTransferAsync(data)
        .then(onClose)
        .catch(err => {
            onError(err);
        });
    };

    if (transferStore.loading || !itemList || !siteMap) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    return (
        <div style={{ marginTop: '1em', paddingRight: '2em' }} >
            <Validated
                id='item'
                label = 'Item'
                schema = {transferSchema}
                value = {transferValue.item}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <StringSelect {...props} allowCreate={true} options={itemList} />}
                />}
            />
            <Validated
                id='unit'
                label = 'Unit'
                schema = {transferSchema}
                value = {transferValue.unit}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <StringSelect {...props} options={unitList} />}
                />}
            />
            <Validated
                id='quantity'
                label = 'Quantity'
                schema = {transferSchema}
                value = {transferValue.quantity}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render = { props => <NumberInput
                        {...props}
                        style={{ width: '12em', marginRight: '0.2em', marginBottom: '0.2em' }}
                    />}
                />}
            />
            <Validated
                id='fromSiteId'
                label = 'From'
                schema = {transferSchema}
                value = {transferValue.fromSiteId}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <EnumSelect {...props} optionsMap={siteMap.all} />}
                />}
            />
            <Validated
                id='toSiteId'
                label = 'To'
                schema = {transferSchema}
                value = {transferValue.toSiteId}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <EnumSelect {...props} optionsMap={siteMap.user} />}
                />}
            />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant='info' onClick={onClose} className='ms-2'>
                    Cancel & Close
                </Button>
                <Button variant='info' onClick={handleSubmit} className='ms-2' disabled={!allValid}>
                    Add Transfer
                </Button>
            </div>
        </div>
    );
});

export { TransferEdit }
