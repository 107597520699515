import React from 'react';
import PropTypes from 'prop-types';

import { NullableSelect } from './NullableSelect';

import { requireWith } from '../utils/propTypes';

/**
 * @classdesc Allow selection of one option from an enumeration.
 */
class EnumSelect extends NullableSelect {
    constructor(props) {
        super(props);

        const options = [];

        this.props.optionsMap.forEach( (value, key) => {
            options.push({ label: value, value: key })
        });

        this.state = { options };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.optionsMap !== prevProps.optionsMap) {
            const options = [];

            this.props.optionsMap.forEach( (value, key) => {
                options.push({ label: value, value: key })
            });

            this.setState({ options });
        }
    }

    handleChange(evt) {
        const value = evt?.value || null;

        if (this.props.onChange) this.props.onChange(this.props.id, value);
    }

    render() {
        let value = this.props.value;

        if (typeof this.props.value === 'boolean' || typeof this.props.value === 'number' || typeof this.props.value === 'string') {
            value = { value, label: this.props.optionsMap.get(value) };
        }

        return (
            <NullableSelect
                id={this.props.id}
                isClearable={this.props.isClearable}
                options={this.state.options}
                onChange={this.handleChange}
                value = {value}
                messageOnNull = {this.props.messageOnNull}
                shadeOnNull = {this.props.shadeOnNull}
                styles={ { container: { width: '100%' } }}
            />
        );
    }
}

EnumSelect.propTypes = {

    /**
     * An identifier. Required if onChange is provided as the handleChange function
     * will call the onChange function and pass it both the formatted value and the id
     * as an argument.
     */
    id: requireWith('onChange', 'string'),

    /**
     * A Map describing the valid options, where:
     * key: <the value returned by onChange and accepted by prop.value>
     * value: <the text description shown in the Select component>
     */
    optionsMap: PropTypes.instanceOf(Map).isRequired,

    /**
     * External event handler for changes. Arguments passed to the onChange function
     * are the 'id' of the component and a formatted version of the object returned
     * from react-select.
     * Must have the signature: (String id, Object value) => undefined
     */
    onChange: PropTypes.func,

    /**
     * The value selected. Must be one of the options in props.optionsMap
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),

    /**
     * Will display this message string if the value is null.
     */
    messageOnNull: PropTypes.string,

    /**
     * Will visually shade the input if the value is null
     */
    shadeOnNull : PropTypes.bool,

    isClearable: PropTypes.bool,
};

export { EnumSelect };
