/* eslint-disable react/prop-types */

import React  from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';

import { UISettings } from '../contexts/UISettings';

import { NullableTextInput } from './general/TextInput';

import { Nav } from './Nav';
import { ToolbarDated } from './Toolbar';

import { ScanSettings } from './scan/ScanSettings';
import { ScanResult } from './scan/ScanResult';
import { ScanInput } from './scan/ScanInput';
import { ScanSummary } from './scan/ScanSummary';

import { OrderStore } from '../store/OrderStore';

const categoriesUseSent = new Set([
    'Sushi and Sushi Lunchboxes',
    'Smoothies',
    'Snacks',
    'Burgers',
    'Sandwiches and Wraps',
    'Late Order- until Eleven AM',
    'Small/Medium/Large lunch boxes',
    'Large Lunchboxes twelve year old to Adult',
    'Small Lunchboxes up to seven year old',
    'Medium Lunchboxes eight to eleven years',
]);

const createOrderStore = (api, orderDate, onError) => {
    const os = new OrderStore(api, orderDate);
    os.load().catch(err => onError(err.toString()));

    return os;
};

const ScanInner = ({api, onError, orderDate, onChangeOrderDate }) => {
    const [manualEntry, setManualEntry] = React.useState(false);
    const [orderStore, setOrderStore] = React.useState(() => createOrderStore(api, orderDate, onError));
    const [scanSettings, setScanSettings] = React.useState(null);
    const [scanResult, setScanResult] = React.useState('prep');
    const [scanOrder, setScanOrder] = React.useState(null);
    const [scanMessage, setScanMessage] = React.useState('You must set scan settings');
    const [barcode, setBarcode] = React.useState(null);
    const [disableScanner, setDisableScanner] = React.useState(false);

    const scanSettingsRef = React.useRef(scanSettings);

    const handleScanSettings = settings => {
        setScanSettings(settings);
        // Feels like a hacky way to fix stale state issue
        scanSettingsRef.current = settings;
        setScanResult('ready');
        setScanMessage('Ready for scanning');
        setScanOrder(null);
    };

    const handleScan = async barcode => {
        const scanSettings = scanSettingsRef.current;

        try {
            const order = orderStore.orderForBarcode(barcode);
            setBarcode(barcode);

            // cases:
            // Order does not exist
            // Order does not match school*
            // Order does not match break*
            // Order date does not match - this shouldn't happen but check anyway
            // Order has been cancelled
            // Order not in correct prior state*
            // Order matches all
            //
            // *Provide option to do the scan anyway, or cancel

            if (order) {
                let messages = [];
                let result = 'ok';
                setScanOrder(order);

                if (order.cancelled) {
                    messages = 'Order has been cancelled';
                    result = 'error';

                } else {
                    for (const match of ['school', 'orderType']) {
                        // eslint-disable-next-line max-depth
                        if (scanSettings[match] !== 'Any' && (order[match] !== scanSettings[match])) {
                            messages[0] = 'Order not in current scan set';
                            messages.push(`${order[match]} does not match expected ${scanSettings[match]}`);
                            result = 'warn';
                        }
                    }

                    if (scanSettings.scanType === 'sent' && !categoriesUseSent.has(order.category)) {
                        result = 'error';
                        messages = `Order with category "${order.category}" should not be sent`;

                    } else if (order.state === 'placed') {
                        messages.push('Order was not marked printed');
                        result = 'ok-warn';

                    } else if (
                        (
                            scanSettings.scanType === 'prepared'
                            && (order.state !== 'printed' && order.state !== 'sent')
                        )
                        || (scanSettings.scanType === 'packed' && order.state !== 'prepared')
                        || (scanSettings.scanType === 'sent' && order.state !== 'printed')
                    ) {
                        messages.push(
                            'Scan state mismatch',
                            `Order was in state: ${order.state}`,
                        );
                        result = 'warn';
                    }

                    if (result.startsWith('ok')) {
                        await orderStore.scanOrderAsync(order.id, scanSettings.scanType);
                        messages.push(`Barcode ${barcode} scanned to ${scanSettings.scanType}`);
                    }
                }

                setScanMessage(messages);
                setScanResult(result);

            } else {
                // FIXME: Force a reload of the data and try again

                setScanMessage(`Order with barcode: ${barcode} not found`);
                setScanResult('error');
                setScanOrder(null);
            }

        } catch (err) {
            console.log(err);
            setScanMessage(['Failed to scan', err.toString()]);
            setScanResult('error');
            setScanOrder(null);
        }
    };

    const pendingInput = pending => {
        // If input is pending elsewhere, disable the scanner so it doesn't
        // get skipped
        setDisableScanner(pending);
    }

    const forceScan = async (barcode, order) => {
        if (!order) return;

        try {
            setScanOrder(order);
            await orderStore.scanOrderAsync(order.id, scanSettings.scanType);
            setScanMessage(`Barcode ${barcode} scanned to ${scanSettings.scanType}`);
            setScanResult('ok');

        } catch (err) {
            console.log(err);
            setScanMessage(['Failed to scan', err.toString()]);
            setScanResult('error');
            setScanOrder(null);
        }
    }


    return (
        <div>
            <Nav />

            <ToolbarDated api={api} orderDate={orderDate} onChangeOrderDate={orderDate => {
                const newOrderStore = createOrderStore(api, orderDate, onError);
                setOrderStore(newOrderStore);
                onChangeOrderDate(orderDate);
            }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <Button
                        variant='info'
                        onClick={() => setManualEntry('')}
                        className='ms-2'
                        disabled={!scanSettings || disableScanner}
                    >
                        Manual Entry
                    </Button>
                    <ScanSettings orderStore={orderStore} onSettings={handleScanSettings} />
                </div>
            </ToolbarDated>

            <ScanSummary
                orderStore={orderStore}
                scanSettings={scanSettings}
            />

            <ScanResult
                orderStore={orderStore}
                result={scanResult}
                order={scanOrder}
                barcode={barcode}
                message={scanMessage}
                onForceScan={forceScan}
                onPendingInput={pendingInput}
            />

            {
                manualEntry === false && scanSettings && !disableScanner
                ? <ScanInput onScan={handleScan} />
                : null
            }

            <Modal
                show={manualEntry !== false}
                onHide={() => setManualEntry(false)}
                centered
                size='lg'
                enforceFocus={false}
            >
                <Modal.Header closeButton> <Modal.Title> Enter Barcode digits: </Modal.Title> </Modal.Header>
                <Modal.Body>
                    <NullableTextInput
                        id='barcode'
                        value={manualEntry}
                        onChange={(_id, v) => setManualEntry(v) }
                        onEnter={() => {
                            const barcode = manualEntry;
                            setManualEntry(false);
                            handleScan(barcode);
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

const Scan = props => {
    return <UISettings.Consumer>{
        ({ pushError, orderDate, setOrderDate }) => <ScanInner {...props} onError={pushError} orderDate={orderDate} onChangeOrderDate={setOrderDate} />
    }</UISettings.Consumer>;
}

Scan.propTypes = {
    api: PropTypes.object.isRequired,
};

export { Scan };
