import React from 'react';
import { NavLink, Redirect, withRouter } from 'react-router-dom';

import { withAuth } from './hoc/withAuth';

import { ErrorSetter } from './Error';

import { UISettings } from '../contexts/UISettings';

import Badge from 'react-bootstrap/Badge';

class Nav extends ErrorSetter {
    constructor(props) {
        super(props);

        this.state = { userPermissions: [], userOrgName: '' };
    }

    componentDidMount() {
        try {
            const user = this.props.authStore.user;
            this.setState({ userPermissions: user.permissions, userOrgName: user.orgName });

        } catch (err) {
            this.setErrorState(err);
        }
    }

    render() {
        if (this.state.fatalError) {
            return (
                <Redirect to={{pathname: '/fatalError', state:{message:this.state.fatalError.message}}}/>
            );
        }

        return (
            <div
                style={{display: 'flex', width: '100%', justifyContent: 'space-between' }}
                className='nav border-bottom'
            >
                <UISettings.Consumer>{({ orderDate }) => <>
                    <ul style={{ paddingLeft: '1em' }} >
                        <li>
                            <NavLink exact
                                activeClassName='nav-active' className='text-info'
                                to='/'>
                            Home
                            </NavLink>
                        </li>
                        {
                            this.state.userPermissions.includes('modifyData')
                            ? <li>
                                <NavLink exact
                                    activeClassName='nav-active' className='text-info'
                                    to='/upload'>
                                Upload
                                </NavLink>
                            </li>
                            : null
                        }
                        {
                            this.state.userPermissions.includes('getData')
                            ? <li>
                                {
                                    orderDate
                                    ? <NavLink exact
                                        activeClassName='nav-active' className='text-info'
                                        to='/orders'>
                                    Orders
                                    </NavLink>
                                    : <span style={{ color: '#ccc' }}>Orders</span>
                                }
                            </li>
                            : null
                        }
                        {
                            (
                                this.state.userPermissions.includes('getData')
                                && this.state.userPermissions.includes('updateScanned')
                            ) ? <li>
                                {
                                    orderDate
                                    ? <NavLink exact
                                        activeClassName='nav-active' className='text-info'
                                        to='/scan'>
                                    Scan
                                    </NavLink>
                                    : <span style={{ color: '#ccc' }}>Scan</span>
                                }
                            </li>
                            : null
                        }
                        {
                            this.state.userPermissions.includes('prepList')
                            ? <li>
                                <NavLink
                                    activeClassName='nav-active' className='text-info'
                                    to='/prepList'>
                                Prep List
                                </NavLink>
                            </li>
                            : null
                        }
                        {
                            this.state.userPermissions.includes('transfer')
                            ? <li>
                                <NavLink
                                    activeClassName='nav-active' className='text-info'
                                    to='/transfers'>
                                Transfers
                                </NavLink>
                            </li>
                            : null
                        }
                        {
                            this.state.userPermissions.includes('updateUsers')
                            ? <li>
                                <NavLink
                                    activeClassName='nav-active' className='text-info'
                                    to='/user'>
                                User
                                </NavLink>
                            </li>
                            : null
                        }
                        <li>
                            <NavLink
                                activeClassName='nav-active' className='text-info'
                                to='/login'>
                            Logout
                            </NavLink>
                        </li>
                    </ul>
                    <div className='me-2'>
                        <Badge bg='info' className='pt-1 pb-1 pe-2'>{(new Date()).toLocaleString()}</Badge>
                    </div>
                </>}</UISettings.Consumer>
            </div>
        );
    }
}

const NavWithRouter = withAuth(withRouter(Nav));

export { NavWithRouter as Nav };
