import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import Creatable   from 'react-select/creatable';

import { NullableSelect } from './NullableSelect';

import { requireWith, nullable } from '../utils/propTypes';

function optionsArray(options) {
    return options.map(e => ({ label: e, value: e }));
}

/**
 * @classdesc Allow selection of one option from an enumeration.
 */
class StringSelect extends NullableSelect {
    constructor(props) {
        super(props);

        this.state = { options: optionsArray(props.options) };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            this.setState({ options: optionsArray(this.props.options) });
        }
    }

    _formatCreateLabel(inputValue) {
        return `Use custom value: ${inputValue}`;
    }

    _isValidNewOption(inputValue) {
        this.newOptionBeingTyped = inputValue;

        return inputValue.length > 0;
    }

    handleChange(evt) {
        if (!this.props.onChange) return;

        if (this.props.isMulti) {
            this.props.onChange(this.props.id, evt?.map(e => e.value) || []);
            return;
        }

        this.props.onChange(this.props.id, evt?.value || '');
    }

    render() {
        let value = undefined;

        if (typeof this.props.value === 'string' && this.props.value) {
            value = { value: this.props.value, label: this.props.value };

        } else if (this.props.value === null) {
            value = null;
        }

        let Component = Select;
        if (this.props.allowCreate === true) Component = Creatable;

        return (
            <Component
                id={this.props.id}
                isClearable={this.props.isClearable}
                isDisabled={this.props.isDisabled}
                isMulti = {this.props.isMulti}
                options={this.state.options}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                value = {value}
                isValidNewOption = {this.props.allowCreate ? this._isValidNewOption : undefined}
                formatCreateLabel = {this.props.allowCreate ? this._formatCreateLabel : undefined}
                styles={{
                    container: styles => ({ ...styles, width: this.props.width || '100%' }),
                }}
            />
        );
    }
}

StringSelect.propTypes = {

    /**
     * An identifier. Required if onChange is provided as the handleChange function
     * will call the onChange function and pass it both the formatted value and the id
     * as an argument.
     */
    id: requireWith('onChange', 'string'),

    isDisabled: PropTypes.bool.isRequired,

    placeholder: PropTypes.string,

    /**
     * A array describing the valid options.
     */
    options: PropTypes.arrayOf(PropTypes.string),

    /**
     * External event handler for changes. Arguments passed to the onChange function
     * are the 'id' of the component and a formatted version of the object returned
     * from react-select.
     * Must have the signature: (String id, Object value) => undefined
     */
    onChange: PropTypes.func,

    /**
     * The value selected. Should be one of the options in props.options, but
     * this is not enforced.
     */
    value: nullable(PropTypes.string),

    isClearable: PropTypes.bool,

    isMulti: PropTypes.bool,

    /**
     * CSS width for the Select element
     */
    width: PropTypes.string,

    /**
     * Allow creation of new options
     */
    allowCreate : PropTypes.bool,
};

StringSelect.defaultProps = {
    isDisabled: false,
    isClearable: false,
};

export { StringSelect };
