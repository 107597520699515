/* eslint-disable react/prop-types */
import React from 'react';

//import Ajv from 'ajv';

import { observer } from 'mobx-react';

import { Spinner, Button } from 'react-bootstrap';

import { FormElement } from '../general/FormElement';
import { SubmitButton } from '../general/SubmitButton';
import { NullableTextInput } from '../general/TextInput';
import { BooleanInput } from '../general/BooleanInput';
import { Password } from '../general/Password';
import { NullableMultiSelect } from '../general/NullableMultiSelect';

import { Validated } from '../validated/Validated';

import { RoleSelect } from './RoleSelect';

import { userEditSchema } from '../../schema/schema';

const UserEdit = observer(
    class UserEdit extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                allValid: false,
                name: props.initialValues?.name || '',
                email: props.initialValues?.email || '',
                existingUser: Boolean(props.initialValues?.name),
                changePassword: false,
                password: {
                    newPassword: '',
                    confirmPassword: '',
                },
                roles: [...(props.initialValues?.roles || props.defaultRoles)],
                sites: [...(props.initialValues?.sites || [])],
            };

            // FIXME: The set of sites available here should just be all sites
            this.siteOpts = props.sites.map(e => ({ value: e.id, label: e.name }));

            // Assume exiting user is valid
            this.invalidSet = new Set(this.state.existingUser ? [] : ['name', 'email', 'password']);

            this.gwSettingsStore = props.gwSettingsStore;

            this.handleChange = this.handleChange.bind(this);

            this.handleSubmit = this.handleSubmit.bind(this);

            this.handleCustomValidation = this.handleCustomValidation.bind(this);
        }

        async componentDidMount() {
            this.setState({ loading: false });
        }

        handleChange(id, value, invalid) {
            if (this.state[id] === undefined) return;

            if (invalid) this.invalidSet.add(id);
            else this.invalidSet.delete(id);

            if (id === 'changePassword') {
                if (value) {
                    this.invalidSet.add('password');

                } else {
                    this.invalidSet.delete('password');
                }

                this.setState({
                    password: { newPassword: '', confirmPassword: '' },
                    [id]: value,
                    allValid: this.invalidSet.size === 0,
                });

            } else {
                this.setState({
                    allValid: this.invalidSet.size === 0,
                    [id]: value,
                });
            }
        }

        async handleSubmit() {
            try {
                await this.props.onSubmit({
                    name: this.state.name,
                    email: this.state.email,
                    password: this.state.password.newPassword,
                    roles: this.state.roles,
                    sites: this.state.sites,
                });
                this.setState({ allValid: undefined });

            } catch(error) {
                console.log(error);
                this.setErrorState(error);

                return false;
            }

            return true;
        }

        handleCustomValidation(id, value) {
            return value.newPassword === value.confirmPassword
            ? {}
            : { password: ['Passwords must match']}
        }

        render() {
            if (this.state.loading) {
                return (
                    <div className='text-center'>
                        <p>Loading</p>
                        <Spinner animation='border' variant='info' />
                    </div>
                );
            }

            const submitDisabled = !this.state.allValid;

            return (
                <>
                    <div className='text-end pt-2 pb-2'>
                        <Button variant='secondary' onClick={this.props.onClose} className='ms-2'>{submitDisabled ? 'Close' : 'Cancel'}</Button>
                        <SubmitButton
                            variant='info'
                            onSubmit={this.handleSubmit}
                            className='ms-2'
                            disabled={submitDisabled}
                            confirmSubmit={false}
                        >{this.state.existingUser ? 'Change' : 'Create'}</SubmitButton>
                    </div>
                    <Validated
                        id='name'
                        label = 'Display name'
                        schema = {userEditSchema}
                        value = {this.state.name}
                        onChange={this.handleChange}
                        render = { props => <FormElement
                            {...props}
                            render={props => <NullableTextInput {...props} />}
                        />}
                    />
                    <Validated
                        id='email'
                        label = 'Login email'
                        schema = {userEditSchema}
                        value = {this.state.email}
                        onChange={this.handleChange}
                        render = { props => <FormElement
                            {...props}
                            render={props => <NullableTextInput {...props} />}
                        />}
                    />
                    {
                        this.state.existingUser
                        ? <FormElement
                            id='changePassword'
                            label = 'Change password?'
                            value = {this.state.changePassword}
                            onChange={this.handleChange}
                            render = { props => <BooleanInput {...props} />}
                        />
                        : null
                    }
                    {
                        this.state.changePassword || !this.state.existingUser
                        ? <Validated
                            id='password'
                            label = 'Password'
                            customValidation={this.handleCustomValidation}
                            schema = {userEditSchema}
                            noMarkupOnLoad = {true}
                            value = {this.state.password}
                            onChange={this.handleChange}
                            render = { props => <>
                                <FormElement
                                    {...props}
                                    errors={{}}
                                    render={ props => <Password
                                        id='password'
                                        {...props}
                                        value={props.value.newPassword}
                                        onChange={(id,v) => props.onChange(id, {...props.value, newPassword: v})}
                                    />}
                                />
                                <FormElement
                                    {...props}
                                    label = 'Confirm password'
                                    render={ props => <Password
                                        {...props}
                                        value={props.value.confirmPassword}
                                        onChange={(id,v) => props.onChange(id, {...props.value, confirmPassword: v})}
                                    />}
                                />
                            </>}
                        />
                        : null
                    }
                    <Validated
                        id='roles'
                        label = 'Roles'
                        schema = {userEditSchema}
                        value = {this.state.roles}
                        onChange={this.handleChange}
                        render = { props => <FormElement
                            {...props}
                            render={props => <RoleSelect {...props} options={this.props.assignableRoles} />}
                        />}
                    />
                    <Validated
                        id='sites'
                        label = 'Sites'
                        schema = {userEditSchema}
                        value = {this.state.sites}
                        onChange={this.handleChange}
                        render = { props => <FormElement
                            {...props}
                            render={props => <NullableMultiSelect {...props} options={this.siteOpts} />}
                        />}
                    />
                </>
            );
        }
    }
);


export { UserEdit };
