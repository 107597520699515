import React from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';
import Creatable   from 'react-select/creatable';

import { requireWith } from '../utils/propTypes';

function limitedMenu(limit, limitMessage) {
    function LimitedMenuRender(props) {
        // eslint-disable-next-line react/prop-types
        const optionSelectedLength = props.getValue().length || 0;

        return (
            <components.Menu {...props}>
                {
                    optionSelectedLength < limit
                    ? ( props.children) // eslint-disable-line react/prop-types
                    : ( <div>{limitMessage}</div>)
                }
            </components.Menu>
        );
    }

    return LimitedMenuRender;
}

class NullableMultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.hasChanged = false;

        this.newOptionBeingTyped = '';

        this.handleChange = this.handleChange.bind(this);
        this._invalidNewOptionMessage = this._invalidNewOptionMessage.bind(this);
        this._isValidNewOption = this._isValidNewOption.bind(this);
    }

    /**
     * @param {object} detail   described in react-select props documentation.
     *                          It is intended for other components that have an
     *                          understanding of the internals of react-select.
     */
    handleChange(evt, detail) {
        const values = [];

        if (evt) {
            for (const field of evt) values.push(field.value);
        }

        this.hasChanged = true;
        this.props.onChange(this.props.id, values, detail);
    }

    _invalidNewOptionMessage() {
        if (this.newOptionBeingTyped.length === 0 && this.props.options.length === 0) {
            return 'No options';
        }

        return this.props.invalidNewOptionMessage || 'invalid';
    }

    _isValidNewOption(inputValue, selectValue, selectOptions) {
        this.newOptionBeingTyped = inputValue;

        return this.props.isValidNewOption(inputValue, selectValue, selectOptions);
    }

    render() {
        const style = {
            container: baseStyles => ({ ...baseStyles, width: '100%' }),
            ...(this.props.styles || {})
        };

        let Component = Select;

        if (this.props.allowCreate === true) Component = Creatable;

        const labels = new Map(this.props.options.map(e => [e.value, e.label]));

        // If props.value is unset, it's important this is undefined so react-select
        // doesn't see the prop at all.
        let value = undefined;

        if (this.props.value) {
            value = [];

            for (const v of this.props.value) {
                const label = labels.get(v) || v;
                value.push({ value: v, label });
            }
        }

        const components = this.props.components || {};

        if (typeof this.props.limit === 'number') {
            components.Menu = limitedMenu(this.props.limit, this.props.limitMessage);
        }

        return (
            <Component
                options={this.props.options}
                isMulti={true}
                isClearable={true}
                onChange={this.handleChange}
                placeholder = {this.props.placeholder}
                styles = { style }
                value = { value }

                isValidNewOption = {this.props.allowCreate ? this._isValidNewOption : undefined}
                noOptionsMessage = {this.props.allowCreate ? this._invalidNewOptionMessage : undefined}

                openMenuOnClick = { this.props.openMenuOnClick }
                isSearchable    = { this.props.isSearchable }
                components      = { components }
                isDisabled      = { this.props.disabled }
            />

        );
    }
}

NullableMultiSelect.defaultProps = {
    limitMessage: 'Limit reached',
    disabled: false,
};

NullableMultiSelect.propTypes = {
    options : PropTypes.array.isRequired,

    /**
     * Placeholder text displayed if nothing is selected.
     */
    placeholder : PropTypes.string,

    /**
     * Limit on the number of options that may be selected at any one time
     */
    limit: PropTypes.number,

    /**
     * Message displayed in place of the menu when the limit is reached
     */
    limitMessage: PropTypes.string,

    /**
     * An array of strings that represent selected options
     */
    value   : PropTypes.array,

    /**
     * User on change handler.
     * See react-select documentation for explanation of the detail parameter.
     * (Number id, Array values, detail) => undefined
     */
    onChange: PropTypes.func,

    /**
     * Allow creation of new options
     */
    allowCreate : PropTypes.bool,

    /**
     * Validation function for new options, with signature:
     * function(string) => boolean
     * Ignored if allowCreate=false.
     */
    isValidNewOption : PropTypes.func,

    /**
     * Message to display if isValidNewOption returns false
     */
    invalidNewOptionMessage: PropTypes.string,

    /**
     * Component HTML id attribute
     */
    id: requireWith('onChange', 'string'),

    /**
     * See docs for react-select
     */
    openMenuOnClick : PropTypes.bool,

    /**
     * See docs for react-select
     */
    isSearchable : PropTypes.bool,

    /**
     * See docs for react-select
     */
    components : PropTypes.object,

    /**
     * Additional react-select styles to be added
     */
    styles: PropTypes.object,

    /**
     * If disabled, the value cannot be changed
     */
    disabled: PropTypes.bool,
};

export { NullableMultiSelect };
