/**
 * Filter function for testing a string against an array of groups with the
 * shape: { name }.
 *
 * @param {String} filterValue  The filter value - a string that must occur
 *                              within one of the group names in 'dataValue'
 * @param {Array}  dataValue    The data the filter is tested against - an
 *                               array of group objects containing a 'name'
 *                               property.
 *
 * @returns {Boolean}   true if dataValue exist in one of the values in filterValue.
 *                      false otherwise.
 */
function groupArrayFilter(filterValue, dataValue) {
    for (const group of dataValue) {
        if (group.name.indexOf(filterValue) > -1) return true;
    }

    return false;
}

/**
 * Filter function for testing a string representing a group name against a
 * group ID which is resolved to a name.
 *
 * @param {Map}     groupMap        A Map with key: group Id and value: group name
 * @param {String}  filterValue     The filter value - a string that must
 *                                  be a substring for the filter to pass
 * @param {number} dataValue        A group ID. The ID is resolved to
 *                                  a name and if filterValue is a substring of the
 *                                  name the filter returns true.
 *
 * @returns {Boolean}   true if the filterValue is found within dataValue,
 *                      false otherwise.
 */
function groupIdFilter(groupMap, filterValue, dataValue) {
    const dataName = groupMap.get(dataValue);

    return dataName.indexOf(filterValue) > -1;
}

/**
 * Filter function for testing a string representing a group name against an
 * array of group IDs which are resolved to names.
 *
 * @param {Map}     groupMap        A Map with key: group Id and value: group name
 * @param {String}  filterValue     The filter value - a string that must
 *                                  be a substring for the filter to pass
 * @param {number[]} dataValue      Array of group IDs. The IDs are resolved to
 *                                  names and if filterValue is a substring of any
 *                                  then the filter returns true.
 *
 * @returns {Boolean}   true if the filterValue is found within dataValue,
 *                      false otherwise.
 */
function groupIdArrayFilter(groupMap, filterValue, dataValue) {
    for (const id of dataValue) {
        const dataName = groupMap.get(id);

        if (dataName.indexOf(filterValue) > -1) return true;
    }

    return false;
}

/**
 * Filter function for testing a string against a boolean representing 'online'
 * and 'offline' status. Comparisons are case insensitive.
 *
 * @param {String}  filterValue     The filter value - a string that must
 *                                  be a substring for the filter to pass
 * @param {Boolean} dataValue       A boolean, where true is treated as a
 *                                  value of 'online' and false is treated as
 *                                  a value of 'offline'.
 *
 * @returns {Boolean}   true if the filterValue is found within dataValue,
 *                      false otherwise.
 */
function onlineFilter(filterValue, dataValue) {
    return (dataValue ? 'online' : 'offline').indexOf(filterValue.toLowerCase()) > -1;
}

/**
 * Filter function for testing a string aginst a numeric value in hexadecimal
 * form.
 *
 * @param {String}  filterValue     The filter value - a string that must
 *                                  be a substring of dataValue for the filter
 *                                  to pass
 * @param {Number}  dataValue       A number, which is converted to a hexadecimal
 *                                  representation (preceeded with 0x) for
 *                                  comparison.
 * @returns {Boolean}   true if the comparison passes (filterValue is found
 *                      within dataValue). false otherwise.
 */
function hexadecimalFilter(filterValue, dataValue) {
    const hexData   = `0x${dataValue.toString(16)}`;

    return hexData.indexOf(filterValue) > -1;
}

/**
 * Filter function for testing a string mac address (12 character hexadecimal
 * without separators) against a formatted mac address with separators.
 *
 * @param {String}  filterValue     The filter value - a string that must
 *                                  be a substring of dataValue for the filter
 *                                  to pass
 * @param {String}  dataValue       A mac address as a 12 character hexadecimal
 *                                  string
 *
 * @returns {Boolean}   true if the comparison passes (filterValue is found
 *                      within dataValue). false otherwise.
 */
function macAddressFilter(filterValue, dataValue) {
    if (!dataValue) return false;

    const a = filterValue.toLowerCase().replace(/:/gu, '');
    const b = dataValue.toLowerCase().replace(/:/gu, '');

    return b.indexOf(a) > -1;
}


/**
 * Filter function for testing an array of tag names against a second
 * array of tag names. All tags in filterValue must be present in dataValue
 * for the comparison to pass.
 *
 * @param {Array} filterValues  The filter values - an array holding tag
 *                              names.
 * @param {Array}  dataValue    An array of strings, each being a tag name.
 *
 * @returns {Boolean}   true if the comparison passes, false otherwise.
 */
function groupTagFilter(filterValues, dataValue) {
    let mustFind = filterValues.length;

    if (mustFind < 1) return true;

    for (const filterValue of filterValues) {
        if (dataValue.find(e => e === filterValue)) {
            if (--mustFind === 0) return true;
        }
    }

    return false;
}

/**
 * Filter function for testing if a string is present within an array of strings.
 * An exact match is required.
 *
 * @param {Array} filterValues  The filter values - an array holding tag
 *                              names.
 * @param {Array}  dataValue    A string to be checked against filterValues
 *
 * @returns {Boolean}   true if dataValue is an element in filterValues.
 */
function arrayFilter(filterValues, dataValue) {
    return filterValues.includes(dataValue);
}

/**
 * Filter function for testing if a single string is within an array of strings.
 * A substring match in any array element is considered a match.
 *
 * @param {Array} filterValues  The filter values - an array holding tag
 *                              names.
 * @param {Array}  dataValue    An array of strings, each being a tag name.
 *
 * @returns {Boolean}   true if the comparison passes, false otherwise.
 */
function arrayAnyFilter(filterValues, dataValue) {
    return dataValue.some(e => e.indexOf(filterValues) > -1);
}

/**
 * Filter function for testing a filterValue against all device types for
 * a row of data.
 * The filterValue must be a substring of any of the rows type, superType,
 * or interfaceType for the comparison to pass.
 *
 * @param {String} filterValue  The filter value - a string that represents
 *                              a device type, superType, or interfaceType
 * @param {Array}  dataValue    The row's data value for the column being
 *                              tested. In this case, this is 'type'.
 * @param {Object} rowData      The entire data row. This filter accesses
 *                              additional properties to perform the comparison,
 *                              not just the property being filtered on.
 *
 * @returns {Boolean}   true the comparison passes, false otherwise.
 */
function filterDeviceType(filterValue, dataValue, rowData) {
    const lowerFilter = filterValue.toLowerCase();

    return (
        dataValue.toLowerCase().indexOf(lowerFilter) > -1
        || rowData.superType.toLowerCase().indexOf(lowerFilter) > -1
        || rowData.interfaceType.toLowerCase().indexOf(lowerFilter) > -1
    );
}

/**
 * Basic filter function that does a substring compare.
 *
 * @param {String} filterValue  The filter value - a string that must occur
 *                              within dataValue to match
 *
 * @param {String}  dataValue   The data the filter is tested against
 *
 * @returns {Boolean}   true the comparison passes, false otherwise.
 */
function textFilter(filterValue, dataValue) {
    return dataValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
}

/**
 * Filter function that does a substring compare comparison, coercing numeric
 * values to strings as required.
 *
 * @param {String} filterValue  The filter value - a string that must occur
 *                              within dataValue to match
 *
 * @param {String}  dataValue   The data the filter is tested against
 *
 * @returns {Boolean}   true the comparison passes, false otherwise.
 */
function numericTextFilter(filterValue, dataValue) {
    return dataValue.toString().indexOf(filterValue.toString()) > -1;
}

/**
 * Filter function that does numeric comparison. Only exact matches pass.
 *
 * @param {String} filterValue  The filter value - a string that must occur
 *                              within dataValue to match
 *
 * @param {String}  dataValue   The data the filter is tested against
 *
 * @returns {Boolean}   true the comparison passes, false otherwise.
 */
function numericFilter(filterValue, dataValue) {
    return (filterValue || 0) === (dataValue || 0);
}


export {
    arrayFilter,
    groupArrayFilter,
    groupIdFilter,
    groupIdArrayFilter,
    onlineFilter,
    hexadecimalFilter,
    macAddressFilter,
    groupTagFilter,
    arrayAnyFilter,
    filterDeviceType,
    textFilter,
    numericTextFilter,
    numericFilter,
};
