/* eslint-disable react/prop-types */

import React  from 'react';
//import { toJS } from 'mobx';

import { Spinner } from 'react-bootstrap';

import { observer } from 'mobx-react';

import { useAuth } from '../../hooks/auth';

import { DataTable } from '../table/DataTable';
import rowTypes from '../table/rowTypes';

import { SubmitButton } from '../general/SubmitButton';
import { StringSelect } from '../general/StringSelect';
import { EnumSelect } from '../general/EnumSelect';
import { ShortDate } from '../general/ShortDate';
import { MappedLabel } from '../general/MappedLabel';

import { asStringCompare } from '../utils/compare';
import { numericFilter } from '../utils/filter';

import { TransferStateSelect } from './TransferStateSelect';

import { ReactComponent as DeleteSVG } from '../../assets/delete.svg';

const stateOpts = ['requested', 'collected', 'delivered'];

const tableSchema = {
    type: 'object',
    properties: {
        state: { type: 'string', title: 'State' },
        stateChangeTime: { type: 'string', title: 'Last changed' },
        requestTime: { type: 'string', title: 'Requested' },
        fromSiteId: { type: 'string', title: 'From' },
        toSiteId: { type: 'string', title: 'To' },
        item: { type: 'string', title: 'Item' },
        quantity: { type: 'integer', title: 'Quantity' },
        unit: { type: 'string', title: 'Unit' },
    }
};

const tableFilters = ['item', 'toSiteId', 'fromSiteId', 'state'];

const TransferDataGrid = observer(({ api, onError, transferStore, style = {} }) => {
    const authStore = useAuth();
    const [siteMap, setSiteMap] = React.useState(null);
    const [itemOpts] = React.useMemo(
        () => {
            const itemSet = new Set();

            for (const e of transferStore.transfers) {
                itemSet.add(e.item);
            }

            return [[...itemSet]];
        },
        [transferStore.transfers]
    );

    React.useEffect(
        () => {
            api.axios.get('/transfers/sites')
            .then(res => {
                const siteMap = new Map(res.data.sites.map(e => [e.id, e.name]));
                setSiteMap(siteMap);
            })
            .catch(err => {
                onError(err);
            });

            return () => {};
        }, []
    );

    const insertDelete = (id, rowType, rowData) => {
        switch (rowType) {
            case rowTypes.filter:
                // Fall through intentional

            case rowTypes.header:
                return [' '];

            case rowTypes.body:
                return [
                    <SubmitButton size='sm' variant='outline-danger'
                        key={`delete_${rowData.id}`}
                        id={rowData.id}
                        onSubmit = {() => transferStore.deleteTransfer([rowData.id]).catch(err => onError(err.toString()))}
                        body={<p>Are you sure you want to delete the entry?</p>}
                    >
                        <DeleteSVG  style = {{height: '1.2rem' }}/>
                    </SubmitButton>
                ];

            default:
                return null;
        }
    };

    if (transferStore.loading || !siteMap) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    return (
        <div style={{ width: '100%', ...style }}>
            <DataTable
                schema={tableSchema}
                filterFor={tableFilters}
                decorate={false}
                borders={false}
                noDataMessage='No Transfers for your sites'
                data={transferStore.transfers}
                uniqueIdField='id'
                headerRowHeight={40}
                insertCells = {
                    authStore.user.permissions.includes('delTransfer')
                    ? insertDelete
                    : undefined
                }
                customCells={{
                    state: {
                        component: <TransferStateSelect transferStore={transferStore} onError={onError} />,
                        filterComponent: <StringSelect options={stateOpts} isClearable={true} />,
                    },
                    item: {
                        filterComponent: <StringSelect options={itemOpts} isClearable={true} />,
                    },
                    stateChangeTime: {
                        component: <ShortDate />
                    },
                    requestTime: {
                        component: <ShortDate />
                    },
                    toSiteId: {
                        component: <MappedLabel map={siteMap} />,
                        filterComponent: <EnumSelect optionsMap={siteMap} isClearable={true} />,
                        compareFn: asStringCompare,
                        filterFn: numericFilter,
                    },
                    fromSiteId: {
                        component: <MappedLabel map={siteMap} />,
                        filterComponent: <EnumSelect optionsMap={siteMap} isClearable={true} />,
                        compareFn: asStringCompare,
                        filterFn: numericFilter,
                    },
                }}
            />
        </div>
    );
});

export {
    TransferDataGrid,
}
