/* eslint-disable react/prop-types */

import React  from 'react';

function ShortDate(props) {
    const d = new Date(props.value);

    const now = new Date();

    if (d.getDate() === now.getDate() && d.getMonth() === now.getMonth() && d.getFullYear() === now.getFullYear()) {
        return <>{d.toLocaleTimeString()}</>;
    }

    return <>{d.toLocaleString()}</>;
}

export { ShortDate };
