/* eslint-disable react/prop-types */

import React  from 'react';

import { observer } from 'mobx-react';

import { ButtonGroup, Button } from 'react-bootstrap';

const TransferStateSelect = observer(({ transferStore, rowData, value, onError }) => {
    const defaultVariant = 'secondary';
    const variants = { [value]: 'info'  };

    const handleClick = newState => {
        transferStore.updateTransferState(rowData.id, newState).catch(err => onError(err.toString()));
    };

    return <ButtonGroup aria-label='Transfer State'>
        <Button size='sm' onClick={() => handleClick('requested')} variant={variants.requested || defaultVariant}>Requested</Button>
        <Button size='sm' onClick={() => handleClick('collected')} variant={variants.collected || defaultVariant}>Collected</Button>
        <Button size='sm' onClick={() => handleClick('delivered')} variant={variants.delivered || defaultVariant}>Delivered</Button>
    </ButtonGroup>;
});

export { TransferStateSelect };
