import { makeObservable, observable, flow } from 'mobx';

// This will load all transfers for all stores the user has access to.

class TransferStore {
    constructor(api) {
        this._transfers = [];

        this.loading = true;

        this._api = api;

        makeObservable(
            this,
            {
                loading: observable,
                _transfers: observable,
                load: flow,

                deleteTransfer: flow,
                createTransferAsync: flow,
                updateTransferState: flow,
            }
        );
    }

    *load() {
        const res = yield this._api.axios.get('/transfers');

        console.log(res.data);

        this._transfers = res.data.transfers;
        this.loading = false;
    }

    get transfers() {
        return this._transfers;
    }

    *deleteTransfer(transferIds) {
        const res = yield this._api.axios.delete(
            '/transfers',
            { params: { transferIds }}
        );

        const deletedSet = new Set(res.data.deleted);

        this._transfers = this._transfers.filter(e => !deletedSet.has(e.id));
    }

    *createTransferAsync(transfer) {
        const res = yield this._api.axios.post('/transfers', transfer);

        console.log(res.data.transfer);

        this._transfers = [...this._transfers, { ...res.data.transfer }];
    }

    *updateTransferState(id, state) {
        const idx = this._transfers.findIndex(e => e.id === id);
        if (idx < 0) return;

        const res = yield this._api.axios.put('/transfers/state', { transferId: id, state });

        this._transfers = this._transfers.toSpliced(idx, 1, res.data);
    }
}

export { TransferStore };
