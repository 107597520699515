import React  from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Toast, ToastContainer } from 'react-bootstrap';

import { UISettings } from '../contexts/UISettings';

import { PrivateRoute } from './PrivateRoute';
import { AppContext } from './AppContext';
import { Landing } from './Landing';
import { HeaderTall } from './headers/HeaderTall';
import { HeaderShort } from './headers/HeaderShort';
import { Footer } from './headers/Footer';

import { AuthExpiryWarning } from './AuthExpiryWarning';
import { Error } from './Error';

import { Home } from './Home';
import { Upload } from './Upload';
import { Orders } from './Orders';
import { Transfers } from './Transfers';
import { Scan } from './Scan';
import { Users } from './user/Users';
import { PrepList } from './PrepList';

import { AuthProvider } from '../hooks/auth';

function Main(props) {

    const handleLoginSuccess = history => {
        history.push('/');
    };

    return (
        <Footer render={ () => (
            <Router>
                <div className='main'>
                    <div className='content'>
                        <AppContext>
                            <AuthProvider api={props.api} >
                                <Switch>
                                    <Route exact path='/'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <Home {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <Route path='/upload'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <Upload {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <Route path='/orders'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <Orders {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <Route path='/scan'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <Scan {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <Route path='/prepList'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <PrepList {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <Route path='/transfers'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <Transfers {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <Route path='/user'>
                                        <PrivateRoute>
                                            <HeaderShort api={props.api}
                                                render={props => <Users {...props} />}
                                            />
                                        </PrivateRoute>
                                    </Route>
                                    <UISettings.Consumer>{
                                        ({setOrderDate}) => <Route
                                            path='/login'
                                            // Use a render prop so that props are passed to the component
                                            render={ routeProps => (
                                                <HeaderTall render={
                                                    () => <Landing
                                                        {...routeProps}
                                                        api={props.api}
                                                        onLoginSuccess={() => {
                                                            setOrderDate(new Date());
                                                            handleLoginSuccess(routeProps.history);
                                                        }}
                                                    />
                                                }/>
                                            )}
                                        />
                                    }</UISettings.Consumer>
                                    <Route path='/fatalError' component={Error} />
                                    <Route component={Error} />
                                </Switch>
                                <UISettings.Consumer>{
                                    ({ errors, clearError }) => errors.map(
                                        e => <ToastContainer position='top-end' className='p-2' key={e}>
                                            <Toast onClick={() => clearError(e)}>
                                                <Toast.Header>
                                                    <strong className='me-auto'>Error!</strong>
                                                    <small>{(new Date()).toLocaleTimeString}</small>
                                                </Toast.Header>
                                                <Toast.Body>{e}</Toast.Body>
                                            </Toast>
                                        </ToastContainer>
                                    )
                                }</UISettings.Consumer>
                                <AuthExpiryWarning />
                            </AuthProvider>
                        </AppContext>
                    </div>
                </div>
            </Router>
        )}/>
    );
}

Main.propTypes = {
    api : PropTypes.object.isRequired
}

export { Main };
