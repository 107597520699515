import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

import { Alert, Table } from 'react-bootstrap';
import Spinner  from 'react-bootstrap/Spinner';

import { ErrorSetter } from './Error';
import { Nav } from './Nav';
import { UserInfo } from './UserInfo';

class Home extends ErrorSetter {
    constructor(props) {
        super(props);

        this.state = { loaded: false };

        this.reports = [];
    }

    async componentDidMount() {
        try {
            this.setState({ loaded: true });
        } catch (error) {
            this.setErrorState(error);
        }
    }

    _renderReports() {
        return (
            <Table striped bordered hover className='mt-2 wide'>
                <thead>
                    <tr><th>Reports</th></tr>
                </thead>
                <tbody>
                    {
                        this.state.loaded
                        ? <>
                            { this.reports.map(report => <tr key={report}><td>{report}</td></tr>) }
                        </>
                        : <tr><td style={{ textAlign: 'center' }}>
                            <Spinner animation='border' variant='info' />
                        </td></tr>
                    }
                </tbody>
            </Table>
        );
    }

    render() {
        if (this.state.fatalError) {
            return (
                <Redirect to={{pathname: '/fatalError', state:{message:this.state.fatalError.message}}}/>
            );
        }

        return (
            <div>
                <Nav />
                <Alert variant='light' className='space-above centered-box'>
                    <Alert.Heading>Local Lunches Hub</Alert.Heading>
                    <hr />
                    <strong>Version:</strong> {process.env.REACT_APP_VERSION}
                    <div style={{ paddingTop: '1em' }}>
                        <UserInfo />
                    </div>
                </Alert>
            </div>
        );
    }
}

Home.propTypes = {
    api : PropTypes.object.isRequired,
    mqttBrokerEnabled: PropTypes.bool,
}

const HomeWithRouter = withRouter(Home);

export { HomeWithRouter as Home };
