/* eslint-disable react/prop-types */

import React  from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { UISettings } from '../contexts/UISettings';

import { TransferStore } from '../store/TransferStore';

//import { useAuth } from '../hooks/auth';

import { TransferDataGrid } from './transfers/TransferDataGrid';
import { TransferEdit } from './transfers/TransferEdit';

import { Nav } from './Nav';
import { Toolbar } from './Toolbar';

const TransfersInner = ({api, onError }) => {
    //const authStore = useAuth();
    const [showPage, setShowPage] = React.useState('list');

    const createTransferStore = () => {
        const ts = new TransferStore(api);

        ts.load().catch(err => onError(err.toString()));

        return ts;
    };

    const [transferStore] = React.useState(() => createTransferStore());

    return (
        <div>
            <Nav />

            <Toolbar api={api} dateDisabled={true} >
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    {
                        showPage === 'list'
                        ? <Button variant='info' onClick={() => setShowPage('addTransfer')} className='ms-2'>
                            Create Transfer
                        </Button>
                        : null
                    }
                </div>
            </Toolbar>

            {
                showPage === 'addTransfer'
                ? <TransferEdit
                    api={api}
                    transferStore={transferStore}
                    onError={onError}
                    onClose={() => setShowPage('list')}
                />
                : null
            }

            <TransferDataGrid
                api={api}
                onError={onError}
                transferStore={transferStore}
                style={showPage === 'list' ? undefined : { display: 'none' }}
            />
        </div>
    );
};

const Transfers = props => {
    return <UISettings.Consumer>{
        ({ pushError }) => <TransfersInner {...props} onError={pushError} />
    }</UISettings.Consumer>;
}

Transfers.propTypes = {
    api: PropTypes.object.isRequired,
};

export {
    Transfers,
}
