/* eslint-disable react/prop-types */
import React  from 'react';

import { Button } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const ToolbarDated = ({ children, orderDate, onChangeOrderDate, dateDisabled }) => {
    const [newOrderDate, setNewOrderDate] = React.useState(null);

    return <div className='toolbar pt-2' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '2rem', paddingRight: '2rem' }}>
        {children}
        <div style={{ display: 'flex' }}>
            <DatePicker
                selected={newOrderDate || orderDate}
                onChange={date => setNewOrderDate(date)}
                dateFormat='dd MMM yyyy'
                todayButton='today'
                disabled={dateDisabled}
            />
            &nbsp;
            <Button
                variant='info'
                disabled={dateDisabled || newOrderDate === null || orderDate.toString() === newOrderDate?.toString()}
                onClick={() => {
                    setNewOrderDate(null);
                    onChangeOrderDate(newOrderDate);
                }}
                style={{ width: '7rem', height: '2.4em' }}
            >
                Set
            </Button>
        </div>
    </div>
};

const Toolbar = ({ children }) => {
    return <div className='toolbar pt-2' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '2rem', paddingRight: '2rem' }}>
        {children}
    </div>
};


export { ToolbarDated, Toolbar };
