//import { schemaPropDesc } from './schemaText';

// This module may duplicate schemas found elsewhere.
// This is fine because the UI is often built from the schemas so we want
// full control over what is presence.
// Where possible, it is good to depend on sub parts of common schemas,
// e.g. to set the schema for an identifier field.

const userNameSchema = { type: 'string', minLength: 1, maxLength: 128 };
const userPassSchema = { type: 'string', minLength: 1, maxLength: 128 };

const userEditSchema = {
    type: 'object',
    required: ['name', 'email'],
    properties: {
        name: userNameSchema,
        email: { type: 'string', format: 'email'},
        password: {
            type: 'object',
            properties: {
                newPassword: userPassSchema,
                confirmPassword: userPassSchema,
            }
        },
        roles: { type: 'array', minItems: 1 },
        sites: { type: 'array' },
    }
};

const userPassChangeSchema = {
    type: 'object',
    required: ['oldPassword', 'password'],
    properties: {
        oldPassword: userPassSchema,
        // Use custom validator for password as it's the only way to ensure
        // they both match
        password: {
            type: 'object',
            properties: {
                password: userPassSchema,
                confirmPassword: userPassSchema,
            }
        }
    }
};

const orderSchema = {
    type: 'object',
    required: ['school', 'customer', 'items', 'orderType', 'dateStr'],
    additionalProperties: false,
    properties: {
        school: { type: 'string', maxLength: 128, minLength: 1 },
        customer: { type: 'string', maxLength: 128, minLength: 1 },
        items: {
            type: 'array',
            minItems: 1,
            items: {
                type: 'object',
                required: ['quantity', 'item'],
                properties: {
                    quantity: { type: 'integer', minimum: 1 },
                    name: { type: 'string', maxLength: 512, minLength: 1 },
                    options: { type: 'string', maxLength: 512 },
                }
            }
        },
        orderType: { type: 'string', maxLength: 32 },
        dateStr: { type: 'string', maxLength: 10 },
        allergies: { type: 'array', items: { type: 'string', maxLength: 128 }},
    },

};

const transferSchema = {
    type: 'object',
    required: ['item', 'quantity', 'unit', 'toSiteId', 'fromSiteId'],
    additionalProperties: false,
    properties: {
        item: { type: 'string', minLength: 1 },
        quantity: { type: 'number', minimum: 1 },
        unit: { enum: ['each', 'batch', 'kg', 'packet', 'carton'] },
        toSiteId: { type: 'integer', minimum: 1 },
        fromSiteId: { type: 'integer', minimum: 1 },
    },

};

export {
    userEditSchema,
    userPassChangeSchema,
    orderSchema,
    transferSchema,
};
