/* eslint-disable react/prop-types */

import React  from 'react';
import PropTypes from 'prop-types';

import { Table, Spinner } from 'react-bootstrap';

import { UISettings } from '../contexts/UISettings';

import { dateToDMY2 } from '../store/OrderStore';

import { Nav } from './Nav';
import { ToolbarDated } from './Toolbar';

import { StringSelect } from './general/StringSelect';

const times = {
    'Ninja Kids, Brookvale, NSW': ['10:00', '10:00'],
    'Brookvale Public School Vacation Care': ['10:00', '10:00'],
    'Lane Cove West Public School, Lane Cove': ['11:40', '14:00'],
    'Ku-Ring-Gai Wildflower Garden': ['12:10', '12:10'],
    'Turramurra Public School OOSH': ['12:15', '12:15'],
    'Northside Montessori Vacation Care': ['11:50', '11:50'],
    'Northside Montessori School, Pymble': ['12:20', '12:20'],
    'Northside Montessori Pre-Primary, Pymble': ['11:20', '11:20'],
    'Killara Public School, Killara': ['11:15', '13:00'],
    'Gordon East Public School': ['11:15', '13:00'],
    'St Ives Public School, St Ives': ['11:00', '12:40'],
    'Turramurra North Public School': ['10:40', '12:50'],
    'Uniting Preschool Turramurra-Turratots': ['9:00', '9:00'],
    'International Chinese School, St Leonards': ['10:30', '10:30'],
};

const serviceMap = { '1ST BREAK': 0, '2ND BREAK': 1 };

function serviceToTime(school, service) {
    if (!times[school]) return service;

    return times[school][serviceMap[service]];
}

function PrepListCount({ key, value }) {
    if (!value) return null;

    const style = {
        fontSize: '1em',
    };

    return <>
        {
            [...Object.entries(value)].map(
                (e, i, a) => {
                    if (e[0] === 'all') return <span style={style} key={`_${key}_all`}>{e[1]}</span>;
                    return <span style={style} key={`_${key}_${i}`}><i>{e[0].toUpperCase()}:</i> {e[1]}{ i < a.length - 1 ? <br /> : null }</span>
                }
            )
        }

    </>;
}

const PrepListInner = ({api, onError, orderDate, onChangeOrderDate }) => {
    const [prepList, setPrepList] = React.useState(null);
    const [schoolOpts, setSchoolOpts] = React.useState(null);
    const [showSchools, setShowSchools] = React.useState(new Set());

    React.useEffect(
        () => {
            api.axios.get(
                '/orders/prepList',
                { params: { orderDateDMY2: dateToDMY2(orderDate) }}
            ).then(res => {
                setSchoolOpts([...new Set(res.data.columns.map(e => e.school.name))]);
                setPrepList(res.data);

            }).catch(err => onError(err));

            return () => {};
        }, [orderDate]
    );

    if (!prepList || !schoolOpts) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    // [ { school: { id, name }, orderType, prepList: {} } ]
    const includedSchools = prepList.columns.filter(e => showSchools.has(e.school.name));

    const showColumns
        = includedSchools.filter(e => Object.keys(e.prepList).length > 0)
        .map(e => ({ ...e, serviceTime: serviceToTime(e.school.name, e.orderType) }))
        .sort((a, b) => a.serviceTime.localeCompare(b.serviceTime));

    const showPrep = new Set();

    for (const col of showColumns) {
        for (const item of Object.keys(col.prepList)) {
            showPrep.add(item);
        }
    }

    return (
        <div>
            <Nav />

            <ToolbarDated api={api} orderDate={orderDate} onChangeOrderDate={onChangeOrderDate}>
                <div style={{ display: 'flex', alignItems: 'baseline', width: '30rem', marginBottom: '0.4em' }}>
                    <StringSelect options={schoolOpts} isMulti={true} onChange={(_id, list) => setShowSchools(new Set(list))} />
                </div>
            </ToolbarDated>

            <h4 className='printOnly'>Prep List {dateToDMY2(orderDate)}</h4>

            <Table striped bordered>
                <thead>
                    <tr>
                        <td>Item</td><td>Cook</td><td>Hold</td>
                        {
                            showColumns.map(
                                (c, j) => <td key={`school_${j}`}>{c.school.name}<br/>{c.serviceTime}</td>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        prepList.rowData.filter(e => showPrep.has(e.item)).map((e, i) => <tr key={e.item}>
                            <td>{e.item}</td>
                            <td>{e.cook}</td>
                            <td>{e.hold}</td>
                            {
                                showColumns.map(
                                    (c, j) => <td key={`${i}_${j}`} align='center'>
                                        <PrepListCount key={`${e.item}_${j}`} value={c.prepList[e.item]} />
                                    </td>
                                )
                            }
                        </tr>)
                    }
                </tbody>
            </Table>
        </div>
    );
};

const PrepList = props => {
    return <UISettings.Consumer>{
        ({ pushError, orderDate, setOrderDate }) => <PrepListInner {...props} onError={pushError} orderDate={orderDate} onChangeOrderDate={setOrderDate} />
    }</UISettings.Consumer>;
}

PrepList.propTypes = {
    api: PropTypes.object.isRequired,
};

export {
    PrepList,
}
