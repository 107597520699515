/* eslint-disable react/prop-types */

import React  from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Form } from 'react-bootstrap';

import { UISettings } from '../contexts/UISettings';

import { OrderStore } from '../store/OrderStore';

import { useAuth } from '../hooks/auth';

import { OrderDataGrid, CancelledOrderDataGrid } from './orders/OrderDataGrid';
import { PrintLabels } from './orders/PrintLabels';
import { OrderEdit } from './orders/OrderEdit';

import { Nav } from './Nav';
import { ToolbarDated } from './Toolbar';

function selectedAsInts(selected) {
    return [...selected].map(e => parseInt(e, 10));
}

const OrdersInner = ({api, onError, orderDate, onChangeOrderDate }) => {
    const authStore = useAuth();
    const [selectedSet, setSelected] = React.useState(new Set());
    const [visibleSet, setVisible] = React.useState(null);
    const [showPage, setShowPage] = React.useState('list');
    const [selectedHasPrinted, setSelectedHasPrinted] = React.useState(false);
    const [printSet, setPrintSet] = React.useState(null);
    const [lastPageOnly, setLastPageOnly] = React.useState(false);

    const createOrderStore = orderDate => {
        const os = new OrderStore(api, orderDate);

        os.load()
        .then(() => {
            const printedCount = os.countOrders({ state: 'printed' });
            setSelectedHasPrinted(printedCount > 0);
        })
        .catch(err => onError(err.toString()));

        return os;
    };

    const [orderStore, setOrderStore] = React.useState(() => createOrderStore(orderDate));

    const handlePrint = () => {
        let subSet = null;

        if (selectedSet.size) subSet = new Set(selectedAsInts(selectedSet));
        else if (visibleSet) subSet = new Set(selectedAsInts(visibleSet));

        const printedCount = orderStore.countOrders({ state: 'printed' }, subSet);

        // Each page has 21 labels
        const labelCount = (subSet?.size || orderStore.orders.length);
        const lastPageNum = (labelCount % 21) || 21;
        const lastPageSet = new Set([...(subSet || orderStore.orders.map(e => e.id.toString()))].slice(labelCount - lastPageNum));

        // If visible and selected are both not set then we want all to be null
        setPrintSet({ all: selectedSet.size ? selectedSet : visibleSet, lastPage: lastPageSet });
        setSelectedHasPrinted(printedCount > 0);
        setShowPage('labels');
    };

    const handlePrinted = async () => {
        try {
            setShowPage('list');
            setSelectedHasPrinted(true);

            if (selectedSet.size) {
                await orderStore.printOrdersAsync(selectedAsInts(selectedSet));

            } else if (visibleSet) {
                await orderStore.printOrdersAsync(selectedAsInts(visibleSet));

            } else {
                await orderStore.printOrdersAsync(null);
            }
        } catch (err) {
            onError(err);
        }
    };

    const handleSelect = selectedRows => {
        setSelected(selectedRows);
    };

    return (
        <div>
            <Nav />

            <ToolbarDated api={api} dateDisabled={showPage !== 'list'} orderDate={orderDate} onChangeOrderDate={orderDate => {
                setOrderStore(createOrderStore(orderDate));
                onChangeOrderDate(orderDate);
            }}>
                {
                    // eslint-disable-next-line no-nested-ternary
                    showPage === 'labels'
                    ? <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button variant='info' onClick={() => window.print()} className='ms-2'>
                            Print
                        </Button>
                        <Button variant='info' onClick={() => handlePrinted()} className='ms-2'>
                            Close & Mark as Printed
                        </Button>
                        <Button variant='info' onClick={() => setShowPage('list')} className='ms-2'>
                            Close & Cancel
                        </Button>

                        <Form.Check
                            id='lastPageToggle'
                            type='switch'
                            checked={lastPageOnly}
                            onChange={e => setLastPageOnly(e.currentTarget.checked)}
                            className='switch ms-4'
                            label='Last Page Only'
                        />
                    </div>
                    : null

                }

                {
                    // eslint-disable-next-line no-nested-ternary
                    showPage === 'cancelled'
                    ? <div style={{ display: 'flex' }}>
                        <Button variant='info' onClick={() => setShowPage('list')} className='ms-2'>
                            Close
                        </Button>
                    </div>
                    : null
                }

                {
                    // eslint-disable-next-line no-nested-ternary
                    showPage === 'addOrder'
                    ? <div style={{ display: 'flex' }}>
                    </div>
                    : null
                }

                <div>
                    {
                        showPage === 'list' && authStore.user.permissions.includes('generateLabels')
                            ? <>
                                <Button variant='info' onClick={handlePrint} className='ms-2'>
                                    Print Labels
                                </Button>
                                <Button variant='info' onClick={() => setShowPage('cancelled')} className='ms-2'>
                                    Cancelled Orders
                                </Button>
                            </>
                            : null
                    }
                    {
                        showPage === 'list' && authStore.user.permissions.includes('modifyData')
                            ? <Button variant='info' onClick={() => setShowPage('addOrder')} className='ms-2'>
                                Add Order
                            </Button>
                            : null
                    }
                </div>
            </ToolbarDated>

            {
                showPage === 'addOrder'
                ? <OrderEdit
                    api={api}
                    orderStore={orderStore}
                    onError={onError}
                    onClose={() => setShowPage('list')}
                />
                : null
            }

            {
                showPage === 'labels'
                ? <PrintLabels
                    orderStore={orderStore}
                    subset={lastPageOnly ? printSet.lastPage : printSet.all}
                />
                : null
            }

            {
                showPage === 'cancelled'
                ? <>
                    <h5>Cancelled Orders</h5>
                    <CancelledOrderDataGrid orderStore={orderStore} />
                </>
                : null
            }

            <OrderDataGrid
                orderStore={orderStore}
                onSelectChange={handleSelect}
                onFilterChange={visibleRows => {
                    setVisible(new Set(visibleRows));
                }}
                style={showPage === 'list' ? undefined : { display: 'none' }}
            />

            <Modal
                show={showPage === 'labels' && selectedHasPrinted}
                centered
                size='lg'
                enforceFocus={false}
            >
                <Modal.Header closeButton> <Modal.Title>Warning</Modal.Title> </Modal.Header>
                <Modal.Body>
                    <div style={{ display:'flex', justifyContent:'space-between' }}>
                        Your selection contains labels already printed.
                        <div>
                            <Button variant='info' onClick={() => setShowPage('list')} className='ms-2' >Cancel</Button>
                            <Button variant='info' onClick={() => setSelectedHasPrinted(false)} className='ms-2' >Proceed</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const Orders = props => {
    return <UISettings.Consumer>{
        ({ pushError, orderDate, setOrderDate }) => <OrdersInner {...props} onError={pushError} orderDate={orderDate} onChangeOrderDate={setOrderDate} />
    }</UISettings.Consumer>;
}

Orders.propTypes = {
    api: PropTypes.object.isRequired,
};

export {
    Orders,
}
